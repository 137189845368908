import request from '@/utils/request'

//获取个人证书列表
export function getvrstudycertificate(params) {
    return request({
        url: '/portal/vr-study-certificate/get-my-list',
        method: 'GET',
        params
    })
}

//获取虚拟仿真证书详情
export function getvrstudycertificateinfo(params) {
    return request({
        url: '/portal/vr-study-certificate/get-info',
        method: 'GET',
        params
    })
}

